* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    background: #060322;
    color: #ffffff;
}

p {
    font-family: 'Overpass', sans-serif;
    margin: unset;
}

.padding {
    padding: 40px 90px;
}

.content_div {
    max-width: 1440px;
    margin: auto;
    margin-top: 117px;
    overflow-x: hidden;
}

.app .mobile_image {
    display: none;
}

/* scroll bar */
html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    border: 0 solid #fff;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, .24);
    border-radius: 20px;
    background: #fff;
}

@media (max-width: 1025px) {
    .padding {
        padding: 40px 50px;
    }
}

@media (max-width: 769px) {
    .padding {
        padding: 20px 40px;
    }

    .content_div {
        margin-top: 112px;
    }

    .app .desktop_image {
        display: none;
    }

    .app .mobile_image {
        display: unset;
    }
}

@media (max-width: 426px) {
    .padding {
        padding: 20px;
    }
}
